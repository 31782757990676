// homePage/index.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectCoverflow } from 'swiper/modules';
import PopupWidget from 'scenes/widgets/PopupWidget.jsx';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow'; // Import Coverflow CSS
import './swiperStyles.css'; // Import the custom CSS
import ReactMarkdown from 'react-markdown';

// Sample data for slides with colors, paths, and arrow fills
const slidesData = [
  {
    title: 'Wettbewerb',
    content:
      'Hol dir deinen Kaleidoskop-Rohling und mach mit beim Wettbewerb! Gestalte dein eigenes Kunstkaleidoskop und sichere dir die Chance auf tolle Sach- und Geldpreise. Schau, bei welchen Partnern du deinen Rohling abholen kannst, und werde Teil der kreativen Kunstkaleidoskop-Community!',
    color: '#1a5e7d', // Color for elements like button background
    image: '/assets/1.jpg',
    path: '/wettbewerb',
    arrowFills: ['#1a5e7d', '#2074b8', '#60ade1'], // Fills for arrows
  },
  {
    title: 'Events',
    content:
      'Unsere Kunst, Kaffee & Mehr-Events bieten eine einzigartige Atmosphäre rund um ein wechselndes Thema, das jeder Veranstalter selbst bestimmt. Erleben Sie kreative Kunstwerke, besondere Kulinarik und inspirierende Themen, die jede Veranstaltung einzigartig machen!',
    color: '#8e1861',
    image: '/assets/2.jpg',
    path: '/events',
    arrowFills: ['#854d74', '#c796b7', '#8e1861'],
  },
  {
    title: 'Partner',
    content:
      'Werden Sie Partner und fördern Sie die Fachkräfte von morgen! Bringen Sie Schüler, Wirtschaft und Kunst zusammen und unterstützen Sie eine starke Verbindung zwischen Bildung und Unternehmertum – für eine gemeinsame Zukunft voller Kreativität und Potenzial.',
    color: '#b39618',
    image: '/assets/3.jpg',
    path: '/partner',
    arrowFills: ['#b39618', '#f2e198', '#e2cb4d'],
  },
  {
    title: 'Wettbewerb',
    content:
      'Hol dir deinen Kaleidoskop-Rohling und mach mit beim Wettbewerb! Gestalte dein eigenes Kunstkaleidoskop und sichere dir die Chance auf tolle Sach- und Geldpreise. Schau, bei welchen Partnern du deinen Rohling abholen kannst, und werde Teil der kreativen Kunstkaleidoskop-Community!',
    color: '#1a5e7d', // Color for elements like button background
    image: '/assets/1.jpg',
    path: '/wettbewerb',
    arrowFills: ['#1a5e7d', '#2074b8', '#60ade1'], // Fills for arrows
  },
  {
    title: 'Events',
    content:
      'Unsere Kunst, Kaffee & Mehr-Events bieten eine einzigartige Atmosphäre rund um ein wechselndes Thema, das jeder Veranstalter selbst bestimmt. Erleben Sie kreative Kunstwerke, besondere Kulinarik und inspirierende Themen, die jede Veranstaltung einzigartig machen!',
    color: '#8e1861',
    image: '/assets/2.jpg',
    path: '/events',
    arrowFills: ['#854d74', '#c796b7', '#8e1861'],
  },
  {
    title: 'Partner',
    content:
      'Werden Sie Partner und fördern Sie die Fachkräfte von morgen! Bringen Sie Schüler, Wirtschaft und Kunst zusammen und unterstützen Sie eine starke Verbindung zwischen Bildung und Unternehmertum – für eine gemeinsame Zukunft voller Kreativität und Potenzial.',
    color: '#b39618',
    image: '/assets/3.jpg',
    path: '/partner',
    arrowFills: ['#b39618', '#f2e198', '#e2cb4d'],
  },
];

// LeftArrow Component
const LeftArrow = ({ fills }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 527 864"
    style={{ width: '100%', height: '100%' }}
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: ${fills[0]}; }
          .cls-2 { fill: ${fills[1]}; }
          .cls-3 { fill: ${fills[2]}; }
        `}
      </style>
    </defs>
    <path
      className="cls-3"
      d="M376.17,402.24c-7.39-.18-83.43-184.7-79.49-197.33,3.93-12.65,219.84-207.26,228.22-204.89,8.38,2.37-141.33,402.4-148.72,402.22Z"
    />
    <path
      className="cls-1"
      d="M376.67,491.63c6.31-1.52,157.61,367.81,149.76,371.84-7.84,4.03-220.02-162.68-223.16-173.33-3.14-10.65,67.11-196.99,73.4-198.5Z"
    />
    <path
      className="cls-2"
      d="M352.95,448.18c-.04,11.7-77.31,220.16-82.85,223.77-5.53,3.59-270.11-189.13-270.11-209.81,0-21.27,257.5-238.68,263.35-236.45,5.86,2.23,89.64,210.8,89.6,222.5Z"
    />
  </svg>
);

// RightArrow Component
const RightArrow = ({ fills }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 527 864"
    style={{ width: '100%', height: '100%' }}
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: ${fills[0]}; }
          .cls-2 { fill: ${fills[1]}; }
          .cls-3 { fill: ${fills[2]}; }
        `}
      </style>
    </defs>
    <path
      className="cls-3"
      d="M150.56,402.24c7.39-.18,83.43-184.7,79.49-197.33C226.12,192.25,10.22-2.35,1.84.02c-8.38,2.37,143.17,402.4,150.72,402.22Z"
    />
    <path
      className="cls-1"
      d="M150.05,491.63c-6.31-1.52-157.61,367.81-149.76,371.84,7.84,4.03,220.02-162.68,223.16-173.33,3.14-10.65-67.11-196.99-73.4-198.5Z"
    />
    <path
      className="cls-2"
      d="M173.77,448.18c.04,11.7,77.31,220.16,82.85,223.77,5.53,3.59,270.11-189.13,270.11-209.81,0-21.27-257.5-238.68-263.35-236.45-5.86,2.23-89.64,210.8-89.6,222.5Z"
    />
  </svg>
);

const HomePage = () => {
  const isMobile = useMediaQuery('(max-width:999px)');
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  const [sectionIndex, setSectionIndex] = useState(0);

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleClick = () => {
    if (swiperInstance?.autoplay) {
      swiperInstance.autoplay.stop();
    }
  };

  const handleShowMore = () => {
    setSectionIndex((prevIndex) => prevIndex + 1);
  };

  const sections = [
    {
      title: 'Kunstkaleidoskop – Kreativität fördern, Mehrwert schaffen',
      content: `
Willkommen bei Kunstkaleidoskop, dem Projekt, das Kunst, Bildung und Unternehmertum miteinander verbindet. Unsere Mission ist es, Schulkinder durch kreative Wettbewerbe zu fördern und gleichzeitig Unternehmen eine Plattform zu bieten, um sich sozial zu engagieren, neue Zielgruppen zu erreichen und die kreative Jugend nachhaltig zu unterstützen.
      `,
    },
    {
      title: 'Unser gemeinsames Ziel',
      content: `
Unser Ziel ist es, allen Schulkindern im Großraum Hamburg die Teilnahme am Kunstkaleidoskop-Wettbewerb kostenfrei zu ermöglichen. Für das Jahr 2025 möchten wir allen Schulen die Teilnahme vollständig sponsern – dank der Unterstützung von Sponsoren und Werbepartnern wie Ihnen. Gemeinsam leisten wir einen wertvollen Beitrag zur Förderung junger Talente und unterstützen die nächste Generation auf ihrem Weg.
      `,
    },
    {
      title: 'Warum Werbepartner werden?',
      content: `
- **Gezielte Sichtbarkeit:** Ihr Unternehmen wird auf unserer Webseite sowie im Partner-Guide Hamburg sichtbar präsentiert – hier erreichen Sie eine engagierte, kreative Community.
- **Zuwachs im Like-Guthaben-Pool:** Mit jedem verkauften Rohling und jeder Unterstützung unserer Werbepartner wächst der Like-Guthaben-Pool, der direkt zur Förderung der Nachwuchstalente beiträgt.
- **Events und Exklusivität:** Als Werbepartner sind Sie Teil unserer Kunst, Kaffee & Mehr-Events. Präsentieren Sie Ihre Produkte und Dienstleistungen in einem inspirierenden Umfeld und vernetzen Sie sich exklusiv mit unserer Zielgruppe.
- **Maßgeschneiderte Aktionen:** Durch abgestufte Like-Belohnungen (z.B. Rabatte oder besondere Erlebnisse bei bestimmten Like-Zahlen) gewinnen Sie potenzielle Kunden und stärken Ihre Markenbindung.
      `,
    },
    {
      title: 'Kunst, Kaffee & Mehr – Die Themenevents',
      content: `
Unsere Kunst, Kaffee & Mehr (KKM)-Events vereinen Kunst, kulinarische Erlebnisse und vielseitige Themenwelten. Jeder Unternehmer kann nicht nur seine Produkte und Dienstleistungen präsentieren, sondern auch als Veranstalter agieren.

Unser Motto lautet: *„Kümmere Dich um Deinen Job, unsere Agenten machen den Rest.“*

Unsere erfahrenen Agenten übernehmen die gesamte Eventorganisation – von der Planung bis zur Umsetzung – sodass Sie sich voll auf Ihr Geschäft konzentrieren können.
      `,
    },
    {
      title: 'Digital vernetzt, echt verbunden',
      content: `
Im Kunstkaleidoskop-Projekt sind Schüler, Unternehmer und Kunstinteressierte digital vernetzt und schaffen dabei echte Verbindungen. Wir bringen Schüler und Unternehmen zusammen, bieten Werbepartnern Sichtbarkeit über den Partner-Guide Hamburg und ermöglichen es Eventveranstaltern, in einem inspirierenden Umfeld von unserer Plattform zu profitieren.

**Digital vernetzt, echt verbunden – ein Netzwerk, das Schüler auf die Wirtschaft vorbereitet, Unternehmern Mehrwert bietet und Künstlern eine Bühne schafft, digital und vor Ort.**
      `,
    },
    {
      title: 'Start des Kunstkaleidoskop-Wettbewerbs 2025',
      content: `
Im Jahr 2025 möchten wir offiziell mit unserem Kunstkaleidoskop-Wettbewerb starten. Für einen erfolgreichen Beginn benötigen wir 100 eingereichte Bild- und Videodokumente sowie 100 Werbepartner. Mit Ihrer Unterstützung können wir dieses kreative Projekt realisieren und gemeinsam einen echten Unterschied machen.
      `,
    },
    {
      title: 'Gestalten Sie mit uns die Zukunft kreativer Jugendförderung. Werden Sie Teil des Kunstkaleidoskops!',
      content: '',
    },
  ];

  const currentSections = sections.slice(0, sectionIndex + 1);

  return (
    <Box>
      <Navbar />
      <PopupWidget />

      {/* Welcome Text */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h2" component="h1">
          Willkommen im Kunstkaleidoskop!
        </Typography>
      </Box>

{/* Swiper Container */}
<Box
  sx={{
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    mt: 2,
    mb: 2,
  }}
>
<Swiper
          modules={[Navigation, Autoplay, EffectCoverflow]} // Include EffectCoverflow
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            scale: 0.85,
          }}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={isMobile ? 1 : 'auto'}
          loop={true}
          spaceBetween={isMobile ? 0 : -50}
          slideToClickedSlide={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onClick={handleClick}
        >
    {slidesData.map((slide, index) => {
      const buttonColor = slide.color;

      return (
        <SwiperSlide
          key={index}
          style={{ width: isMobile ? '100%' : '60%' }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: 'auto',
            }}
          >
            {/* Slide Image */}
            <Box
              component="img"
              src={slide.image}
              alt={slide.title}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />

            {/* Red Triangle in the Top-Right Corner */}
            {slide.title === 'Events' && (
  <Box
    onClick={() => navigate('/events')}
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      width: 0,
      height: 0,
      borderTop: {
        xs: '100px solid red',
        sm: '120px solid red',
        md: '160px solid red',
        lg: '200px solid red',
      },
      borderLeft: {
        xs: '100px solid transparent',
        sm: '120px solid transparent',
        md: '160px solid transparent',
        lg: '200px solid transparent',
      },
      cursor: 'pointer',
    }}
  >
    <Typography
      sx={{
        position: 'absolute',
        top: {
          xs: '-80px',
          sm: '-100px',
          md: '-135px',
          lg: '-165px',
        },
        right: {
          xs: '3px',
          sm: '3px',
          md: '10px',
          lg: '10px',
        },
        transform: 'rotate(45deg)',
        color: 'white',
        fontSize: {
          xs: '0.8rem',
          sm: '1rem',
          md: '1.2rem',
          lg: '1.5rem',
        },
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      }}
    >
      Startevent<br />buchen
    </Typography>
  </Box>
)}


            {/* First Container */}
            <Box
              sx={{
                position: 'absolute',
                bottom: '10px',
                left: '30px',
                width: { xs: '55%', md: '70%' },
                lineHeight:'1.0'
              }}
            >
              {/* Top Container */}
              <Box
                sx={{
                  display: 'inline-flex',
                  position: 'relative',
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  backgroundColor: theme.palette.background.paper,
                  overflow: 'hidden',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: { xs: '5px', md: '10px' },
                  paddingTop: { xs: '5px', md: '5px' },
                  paddingBottom: { xs: '0px', md: '0px' },
                  marginBottom: '-1px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    color: buttonColor,
                    textAlign: 'center',
                    fontSize: { xs: '0.8rem', md: '1.2rem' },
                  }}
                >
                  {slide.title}
                </Typography>
              </Box>

              {/* Bottom Container */}
              <Box
                sx={{
                  display: 'inline-flex',
                  borderBottomLeftRadius: '15px',
                  borderBottomRightRadius: '15px',
                  borderTopRightRadius: '15px',
                  backgroundColor: theme.palette.background.paper,
                  padding: { xs: '7px', md: '10px' },
                  paddingRight: { xs: '5px', md: '5px' },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: buttonColor,
                    fontSize: { xs: '0.5rem', md: '1rem' },
                  }}
                >
                  {slide.content}
                </Typography>
              </Box>
            </Box>

            {/* Second Container as a Button */}
            <ButtonBase
              onClick={() => navigate(slide.path)}
              sx={{
                position: 'absolute',
                bottom: '10px',
                right: { xs: '10px', sm: '30px', md: '30px' },
                borderRadius: '30px',
                backgroundColor: buttonColor,
                padding: { xs: '10px', md: '20px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'white',
                  fontSize: { xs: '0.6rem', md: '1.2rem' },
                  fontWeight: 'bold',
                }}
              >
                {(slide.title === "Wettbewerb" || slide.title === "Partner") ? "zum" : "zu"} {slide.title}
              </Typography>
            </ButtonBase>
          </Box>
        </SwiperSlide>
      );
    })}

    {/* Custom Previous Button */}
    <Box
      className="swiper-button-prev"
      sx={{
        position: 'absolute',
        left: { xs: '0px', sm: '10px', md: '10px' },
        top: '50%',
        zIndex: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: { xs: '30px', md: '60px' },
        height: { xs: '30px', md: '60px' },
        transition: 'transform 0.3s ease, background-color 0.3s ease',
        '&::after': {
          display: 'none', // Hide default Swiper arrows
        },
      }}
    >
      <LeftArrow fills={slidesData[activeIndex].arrowFills} />
    </Box>

    {/* Custom Next Button */}
    <Box
      className="swiper-button-next"
      sx={{
        position: 'absolute',
        right: { xs: '0px', sm: '10px', md: '10px' },
        top: '50%',
        zIndex: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: { xs: '30px', md: '60px' },
        height: { xs: '30px', md: '60px' },
        transition: 'transform 0.3s ease, background-color 0.3s ease',
        '&::after': {
          display: 'none', // Hide default Swiper arrows
        },
      }}
    >
      <RightArrow fills={slidesData[activeIndex].arrowFills} />
    </Box>
  </Swiper>
</Box>


{/* New Content Below Swiper */}
<Box
  sx={{
    mt: { xs: 4, md: 10 },
    px: { xs: 2, md: 10 },
    maxWidth: '1200px',
    margin: '0 auto',
  }}
>
  {currentSections.map((section, index) => (
    <Box key={index} sx={{ marginBottom: '20px' }}>
      {section.title && (
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
          {section.title}
        </Typography>
      )}
      {section.content && (
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <Typography variant="body1" sx={{ fontSize: '16' }} paragraph {...props} />
            ),
            strong: ({ node, ...props }) => <strong {...props} />,
            em: ({ node, ...props }) => <em {...props} />,
            ul: ({ node, ...props }) => (
              <List
                sx={{
                  paddingLeft: '1em',
                  marginTop: 0,
                  marginBottom: 0,
                }}
                {...props}
              />
            ),
            li: ({ node, ...props }) => (
              <ListItem
                sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}
                disableGutters
                disablePadding
              >
                <Typography variant="body1" sx={{ fontSize: '1rem' }} component="span" {...props} />
              </ListItem>
            ),
          }}
        >
          {section.content}
        </ReactMarkdown>
      )}
      {index < sections.length - 1 && index === sectionIndex && (
        <Box textAlign="center">
          <Button variant="text" onClick={handleShowMore} sx={{ marginTop: '10px' }}>
            Mehr erfahren
          </Button>
        </Box>
      )}
    </Box>
  ))}
</Box>

      <Footer />
    </Box>
  );
};

export default HomePage;