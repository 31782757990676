// footer/index.jsx
import {
    Box,
    Typography,
    Link,
    useTheme,
    useMediaQuery,
    IconButton,
    Divider,
  } from "@mui/material";
  import {
    Email,
    Phone,
    YouTube,
  Facebook,
    Instagram,
    LocationOn,
    AccessTime,
  } from "@mui/icons-material";
  import { useNavigate, Link as RouterLink } from "react-router-dom";

  
  const Footer = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
    const alt = theme.palette.background.alt;
    const navigate = useNavigate();
  
    return (
      <Box
        component="footer"
        width="100%"
        backgroundColor={alt}
        padding="2rem 6%"
        mt="2rem"
      >
        {/* Main Content Area */}
        <Box
          display="flex"
          flexDirection={isNonMobileScreens ? "row" : "column"}
          justifyContent="space-between"
          alignItems={isNonMobileScreens ? "flex-start" : "center"}
          textAlign={isNonMobileScreens ? "left" : "center"}
          gap="2rem"
        >
          {/* Left Column: Company Information */}
          <Box flexBasis="45%">
            <Typography variant="h6" gutterBottom>
              Kunstkaleidoskop
            </Typography>
            <Box display="flex" justifyContent={isNonMobileScreens ? "flex-start" : "center"} alignItems="center" gap="0.5rem">
              <LocationOn />
              <Typography>
              Andreas Metz
              <br />
                Dr. Peter Hoffmann
                <br />
                Cremon 11
                <br />
                20457 Hamburg
              </Typography>
            </Box>
  
            {/* Opening Hours */}
            <Box mt="1rem">
              <Typography variant="h6" gutterBottom>
                Öffnungszeiten
              </Typography>
              <Box display="flex" alignItems="center" gap="0.5rem">
                <AccessTime />
                <Typography>
                  Mo – Fr: 12:00 Uhr – 18:00 Uhr
                  <br />
                  Termine nach Vereinbarung, auch am Wochenende.
                </Typography>
              </Box>
            </Box>
          </Box>
  
          {/* Right Column: Contact Information and Social Media Icons */}
          <Box
            flexBasis="45%"
            display="flex"
            flexDirection="column"
            alignItems={isNonMobileScreens ? "flex-end" : "center"}
            textAlign={isNonMobileScreens ? "right" : "center"}
            gap="1rem"
          >
            {/* Contact Heading */}
            <Typography variant="h6" gutterBottom>
            <Link
              component={RouterLink}
              to="/kontakt"
              color="inherit"
              underline="hover"
            >
          Kontakt
        </Link>
      </Typography>
            {/* Email */}
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Email />
              <Link
                href="mailto:info@kunstkaleidoskop.de"
                color="inherit"
                underline="hover"
              >
                info@kunstkaleidoskop.de
              </Link>
            </Box>
            {/* Phone */}
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Phone />
              <Link
                href="tel:+4916099764318"
                color="inherit"
                underline="hover"
              >
                +49 (0) 160 99764318
              </Link>
            </Box>
            {/* Social Media Icons */}
            <Box display="flex" gap="1rem">
              <IconButton
                component="a"
                href="https://www.youtube.com/@Kunstkaleidoskop"
                target="_blank"
                rel="noopener"
              >
                <YouTube />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.instagram.com/kunstkaleidoskop/"
                target="_blank"
                rel="noopener"
              >
                <Instagram />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.tiktok.com/@kunstkaleidoskop"
                target="_blank"
                rel="noopener"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? '#fff'
                      : 'rgba(0, 0, 0, 0.54)',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  shapeRendering="geometricPrecision"
                  textRendering="geometricPrecision"
                  imageRendering="optimizeQuality"
                  width="24"
                  height="24"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  viewBox="0 0 449.45 515.38"
                  fill="currentColor"
                >
                  <path
                    fillRule="nonzero"
                    d="M382.31 103.3c-27.76-18.1-47.79-47.07-54.04-80.82-1.35-7.29-2.1-14.8-2.1-22.48h-88.6l-.15 355.09c-1.48 39.77-34.21 71.68-74.33 71.68-12.47 0-24.21-3.11-34.55-8.56-23.71-12.47-39.94-37.32-39.94-65.91 0-41.07 33.42-74.49 74.48-74.49 7.67 0 15.02 1.27 21.97 3.44V190.8c-7.2-.99-14.51-1.59-21.97-1.59C73.16 189.21 0 262.36 0 352.3c0 55.17 27.56 104 69.63 133.52 26.48 18.61 58.71 29.56 93.46 29.56 89.93 0 163.08-73.16 163.08-163.08V172.23c34.75 24.94 77.33 39.64 123.28 39.64v-88.61c-24.75 0-47.8-7.35-67.14-19.96z"
                  />
                </svg>
              </IconButton>

            </Box>
          </Box>
        </Box>
  
        {/* Bottom Area: Links with Dividers and Copyright */}
        <Box
          mt="2rem"
          display="flex"
          flexDirection={isNonMobileScreens ? "row" : "column"}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          gap="1rem"
        >
          {/* Links with Dividers */}
          <Box display="flex" alignItems="center" gap="1rem" fontSize='0.8rem'>
            <Link
              component={RouterLink}
              to="/datenschutz"
              color="inherit"
              underline="hover"
            >
              Datenschutz
            </Link>
            <Divider orientation="vertical" flexItem />
            <Link
              component={RouterLink}
              to="/impressum"
              color="inherit"
              underline="hover"
            >
              Impressum
            </Link>
            <Divider orientation="vertical" flexItem />
            <Link
              component={RouterLink}
              to="/agb"
              color="inherit"
              underline="hover"
            >
              AGB
            </Link>
          </Box>
        </Box>
  
        {/* Copyright */}
        <Box mt="1rem" textAlign="center">
          <Typography variant="body2" color="textSecondary">
            © 2025 MediConsult GmbH. Alle Rechte vorbehalten.
          </Typography>
        </Box>
      </Box>
    );
  };
  
  export default Footer;
  