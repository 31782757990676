// kontakt/index.jsx

import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Grid,
  FormHelperText,
} from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';

const KontaktPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:999px)');

  useEffect(() => {
    // Function to load the HubSpot script
    const loadHubSpotForm = () => {
      // Check if the script is already present
      if (document.getElementById('hs-script-loader')) {
        initializeHubSpotForm();
        return;
      }

      // Create the script element
      const script = document.createElement('script');
      script.id = 'hs-script-loader';
      script.src = 'https://js.hsforms.net/forms/embed/v2.js';
      script.async = true;
      script.defer = true;

      // Append the script to the body
      document.body.appendChild(script);

      // Initialize the form once the script is loaded
      script.onload = () => {
        initializeHubSpotForm();
      };
    };

    // Function to initialize the HubSpot form
    const initializeHubSpotForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7390332',
          formId: '9519ca39-0a25-4f71-be32-3369dad71f2d',
          target: '#hubspot-form',
          onFormReady: function () {
            insertMissingText();
            implementConditionalRequiredFields();

            const formIframe = document.querySelector('.hs-form-iframe');
            if (formIframe) {
              const iframeDocument = formIframe.contentDocument || formIframe.contentWindow.document;
              const submissionUrlField = iframeDocument.querySelector('input[name="submission_url"]');
              if (submissionUrlField) {
                submissionUrlField.value = window.location.href;
              }

              // 1. Hide the hs_recaptcha element
              const recaptcha = iframeDocument.querySelector('.hs_recaptcha');
              if (recaptcha) {
                recaptcha.style.display = 'none';
              }

              // 2. Center the submit button
              const actionsDiv = iframeDocument.querySelector('.actions');
              // if (actionsDiv) {
              //   actionsDiv.style.display = 'flex';
              //   actionsDiv.style.justifyContent = 'center';
              // }

              // 3. Move "*Pflichtfelder" before the submit button
              const pflichtfelderText = iframeDocument.createElement('p');
              pflichtfelderText.textContent = '*Pflichtfelder';
              pflichtfelderText.style.fontFamily = 'Rubik, sans-serif';
              actionsDiv.parentNode.insertBefore(pflichtfelderText, actionsDiv);

              // 4. Center the submitted-message text
              // const submittedMessage = iframeDocument.querySelector('.submitted-message');
              // if (submittedMessage) {
              //   submittedMessage.style.textAlign = 'center';
              // }

              const style = iframeDocument.createElement('style');
              style.textContent = `
                .submitted-message {
                  text-align: center;
                }
              `;
              iframeDocument.head.appendChild(style);

              // 5. Move "Jetzt hier Eintragen" into the iframe
              // const jetztEintragenText = iframeDocument.createElement('h5');
              // const hbsptform = iframeDocument.querySelector('.hbspt-form')
              // jetztEintragenText.textContent = 'Jetzt hier Eintragen';
              // jetztEintragenText.style.fontFamily = 'Rubik, sans-serif';
              // jetztEintragenText.style.fontSize = "1rem";
              // jetztEintragenText.style.textAlign = "center";
              // hbsptform.insertBefore(jetztEintragenText, hbsptform.firstChild);
            }
          },
          onFormSubmitted: function () {
          // Mark the form as submitted so popup no longer appears
          document.cookie = "formSubmitted=true; path=/; max-age=31536000";
          },
        });
      }
    };

    // Function to insert the missing text
    const insertMissingText = () => {
      const formIframe = document.querySelector('.hs-form-iframe');
      if (!formIframe) return;
    
      const iframeDocument = formIframe.contentDocument || formIframe.contentWindow.document;
      const emailField = iframeDocument.querySelector('div.hs-email');
    
      if (emailField) {
        const missingText = iframeDocument.createElement('p');
        missingText.textContent = '...oder tragen Sie Ihr Unternehmen gleich mit ein';
        missingText.style.marginTop = '20px';
        missingText.style.fontWeight = 'bold';
        missingText.style.fontFamily = 'Rubik, sans-serif';
        // missingText.style.fontSize = '13px';
        emailField.parentNode.insertBefore(missingText, emailField.nextSibling);
      }
    };

    // Function to implement conditional required fields
    const implementConditionalRequiredFields = () => {
      const formIframe = document.querySelector('.hs-form-iframe');
      
      if (!formIframe) {
        console.error('Form iframe not found');
        return;
      }
    
      const iframeDocument = formIframe.contentDocument || formIframe.contentWindow.document;
    
      const companyField = iframeDocument.querySelector('input[name="0-2/name"]');
      const websiteField = iframeDocument.querySelector('input[name="0-2/website"]');
      const locationField = iframeDocument.querySelector('input[name="0-2/city"]');
    
      // Helper functions to add and remove asterisks
      const addAsteriskToLabel = (labelId) => {
        const label = iframeDocument.getElementById(labelId);
        if (label) {
          // Only add asterisk if it doesn't already exist
          if (!label.querySelector('.hs-form-required')) {
            const span = label.querySelector('span');
            const asterisk = iframeDocument.createElement('span');
            asterisk.className = 'hs-form-required';
            asterisk.textContent = '*';
            if (span) {
              span.parentNode.insertBefore(asterisk, span.nextSibling);
            }
          }
        }
      };
    
      const removeAsteriskFromLabel = (labelId) => {
        const label = iframeDocument.getElementById(labelId);
        if (label) {
          const asterisk = label.querySelector('.hs-form-required');
          if (asterisk) {
            asterisk.remove();
          }
        }
      };
    
      // Function to add error message and styling
      const addErrorMessage = (field) => {
        const fieldContainer = field.closest('.hs-form-field');
        if (fieldContainer) {
          // Add error class to input
          field.classList.add('error');
          field.style.borderColor = '#c87872';
    
          // Check if error message container already exists
          let errorContainer = fieldContainer.querySelector('.hs-error-msgs');
          if (!errorContainer) {
            errorContainer = iframeDocument.createElement('ul');
            errorContainer.className = 'hs-error-msgs inputs-list';
            const errorItem = iframeDocument.createElement('li');
            const errorLabel = iframeDocument.createElement('label');
            errorLabel.className = 'hs-error-msg';
            errorLabel.textContent = 'Bitte füllen Sie dieses Pflichtfeld aus.';
            errorItem.appendChild(errorLabel);
            errorContainer.appendChild(errorItem);
            fieldContainer.appendChild(errorContainer);
          }
          fieldContainer.classList.add('hs-error');
        }
      };
    
      // Function to remove error message and styling
      const removeErrorMessage = (field) => {
        const fieldContainer = field.closest('.hs-form-field');
        if (fieldContainer) {
          // Remove error class from input
          field.classList.remove('error');
          field.style.borderColor = '';
    
          const errorContainer = fieldContainer.querySelector('.hs-error-msgs');
          if (errorContainer) {
            errorContainer.remove();
          }
          fieldContainer.classList.remove('hs-error');
        }
      };
    
      const toggleRequired = () => {
        const isAnyFieldFilled =
          companyField.value.trim() !== '' ||
          websiteField.value.trim() !== '' ||
          locationField.value.trim() !== '';

        if (isAnyFieldFilled) {
          // Add required attributes and visual indicators
          companyField.setAttribute('data-required', 'true');
          websiteField.setAttribute('data-required', 'true');
          locationField.setAttribute('data-required', 'true');

          // Add asterisks to labels
          addAsteriskToLabel('label-0-2/name-9519ca39-0a25-4f71-be32-3369dad71f2d'); // Company field label
          addAsteriskToLabel('label-0-2/website-9519ca39-0a25-4f71-be32-3369dad71f2d');
          addAsteriskToLabel('label-0-2/city-9519ca39-0a25-4f71-be32-3369dad71f2d');

          // Check if fields are empty and show error messages
          if (!companyField.value.trim()) {
            addErrorMessage(companyField);
          }
          if (!websiteField.value.trim()) {
            addErrorMessage(websiteField);
          }
          if (!locationField.value.trim()) {
            addErrorMessage(locationField);
          }
        } else {
          // Remove required attributes and visual indicators
          companyField.removeAttribute('data-required');
          websiteField.removeAttribute('data-required');
          locationField.removeAttribute('data-required');

          // Remove asterisks from labels
          removeAsteriskFromLabel('label-0-2/name-9519ca39-0a25-4f71-be32-3369dad71f2d'); // Company field label
          removeAsteriskFromLabel('label-0-2/website-9519ca39-0a25-4f71-be32-3369dad71f2d');
          removeAsteriskFromLabel('label-0-2/city-9519ca39-0a25-4f71-be32-3369dad71f2d');

          // Remove error messages
          removeErrorMessage(companyField);
          removeErrorMessage(websiteField);
          removeErrorMessage(locationField);
        }
      };
    
      // Initial check
      toggleRequired();
    
      // Add event listener for company field
      companyField.addEventListener('input', toggleRequired);
      websiteField.addEventListener('input', toggleRequired);
      locationField.addEventListener('input', toggleRequired);
    
      // Update event listeners for all fields
      companyField.addEventListener('input', function () {
        if (websiteField.value.trim() !== '' || locationField.value.trim() !== '') {
          if (this.value.trim()) {
            removeErrorMessage(this);
          } else {
            addErrorMessage(this);
          }
        }
      });

      websiteField.addEventListener('input', function () {
        if (companyField.value.trim() !== '' || locationField.value.trim() !== '') {
          if (this.value.trim()) {
            removeErrorMessage(this);
          } else {
            addErrorMessage(this);
          }
        }
      });

      locationField.addEventListener('input', function () {
        if (companyField.value.trim() !== '' || websiteField.value.trim() !== '') {
          if (this.value.trim()) {
            removeErrorMessage(this);
          } else {
            addErrorMessage(this);
          }
        }
      });
    
      // Form submission handler
      const form = iframeDocument.querySelector('form');
      if (form) {
        form.addEventListener('submit', (event) => {
          const isAnyFieldFilled =
            companyField.value.trim() !== '' ||
            websiteField.value.trim() !== '' ||
            locationField.value.trim() !== '';

          if (isAnyFieldFilled) {
            const isCompanyEmpty = !companyField.value.trim();
            const isWebsiteEmpty = !websiteField.value.trim();
            const isLocationEmpty = !locationField.value.trim();

            if (isCompanyEmpty || isWebsiteEmpty || isLocationEmpty) {
              event.preventDefault();
              event.stopPropagation();

              if (isCompanyEmpty) {
                addErrorMessage(companyField);
              }
              if (isWebsiteEmpty) {
                addErrorMessage(websiteField);
              }
              if (isLocationEmpty) {
                addErrorMessage(locationField);
              }

              // Return false to prevent form submission
              return false;
            }
          }
        }, true);
      }
    };

    // Get the current pathname and search (query string)
    const currentPath = window.location.pathname;
    const currentQuery = window.location.search;

    // loadHubSpotForm();

    if (currentPath === '/kontakt' && currentQuery !== '?Kontaktaufnahme') {
      loadHubSpotForm();
    }

    // Embed HubSpot tracking code
    function loadTrackingScript() {
      const trackingScript = document.createElement('script');
      trackingScript.type = 'text/javascript';
      trackingScript.id = 'hs-script-loader-tracking';
      trackingScript.async = true;
      trackingScript.defer = true;
      trackingScript.src = '//js.hs-scripts.com/7390332.js';
      document.body.appendChild(trackingScript);
    }

    // Check if the URL is exactly '/kontakt?Kontaktaufnahme'
    if (currentPath === '/kontakt' && currentQuery === '?Kontaktaufnahme') {
        loadTrackingScript();

        // 1) Skip showing the banner if cookies have already been accepted
        if (localStorage.getItem('cookiesAccepted') === 'true') {
          loadHubSpotForm();
          return
        }

        // Cookies
        // 1. Inject CSS to center and style the cookie banner + overlay
        const styleEl = document.createElement('style');
        styleEl.innerHTML = `
          /* Center the cookie banner */
          #hs-eu-cookie-confirmation {
            position: fixed !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            z-index: 99999 !important;
            background: #fff !important;
            padding: 20px !important;
            border-radius: 8px !important;
            box-shadow: 0 2px 10px rgba(0,0,0,0.3) !important;
            display: flex !important;
            align-items: center !important;
            width: auto !important;
          }

          @media (max-width: 768px) {
            #hs-eu-cookie-confirmation {
              width: 100% !important;
            }
          }

          div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
            bottom: unset !important;
          }

          div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
            margin-right: 0px !important;
            justify-content: center !important;
          }

          /* Ensure inner text is centered nicely */
          #hs-eu-cookie-confirmation-inner {
            text-align: center;
          }

          /* The dimmed background overlay */
          #cookie-overlay {
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
            background-color: rgba(0, 0, 0, 0.5) !important;
            z-index: 99998 !important;
          }

          /*
            Blur and block interaction on everything except
            the cookie banner when it is visible.
          */
          body.cookie-banner-visible *:not(#hs-eu-cookie-confirmation, 
                                          #hs-eu-cookie-confirmation *, 
                                          #cookie-overlay) {
            filter: blur(2px);
            pointer-events: none;
            user-select: none;
          }

          #hs-eu-decline-button {
            display: none !important;
          }
        `;
        document.head.appendChild(styleEl);

        // 2. Create and add the overlay
        const overlay = document.createElement('div');
        overlay.id = 'cookie-overlay';
        document.body.appendChild(overlay);

        // 3. Make sure the body knows the banner is visible
        document.body.classList.add('cookie-banner-visible');

        // 4. Hide banner + overlay after a button click
        function hideCookieBanner() {
          localStorage.setItem('cookiesAccepted', 'true');
          
          window.location.reload();
          
          const banner = document.getElementById('hs-eu-cookie-confirmation');
          if (banner) {
            // Remove the banner from the DOM altogether
            banner.remove();
          }
        
          // Remove the overlay
          overlay.remove();
        
          // Remove the body class to restore interactivity
          document.body.classList.remove('cookie-banner-visible');

          loadHubSpotForm();
        }        

        // 5. Attach listeners to the accept/decline buttons (if they exist yet)
        function attachCookieListeners() {
          const acceptBtn = document.getElementById('hs-eu-confirmation-button');
          const declineBtn = document.getElementById('hs-eu-decline-button');
          if (acceptBtn) acceptBtn.addEventListener('click', hideCookieBanner);
          if (declineBtn) declineBtn.addEventListener('click', hideCookieBanner);
          // Create the leave button if it doesn't exist yet
          const confirmBtn = document.getElementById('hs-eu-confirmation-button');
          if (confirmBtn) {
            let leaveBtn = document.getElementById('hs-eu-leave-button');
            if (!leaveBtn) {
              leaveBtn = document.createElement('a');
              leaveBtn.href = 'javascript:void(0);';
              leaveBtn.id = 'hs-eu-leave-button';
              leaveBtn.role = 'button';
              leaveBtn.textContent = 'Nein, Seite verlassen';
              leaveBtn.style.marginRight = '10px';

              // Insert the leave button before the confirm button
              confirmBtn.insertAdjacentElement('beforebegin', leaveBtn);

              leaveBtn.addEventListener('click', () => {
                // Attempt to close the tab (some browsers may block this)
                window.open('', '_self');
                window.close();

                // Fallback if closing is blocked
                setTimeout(() => {
                  window.location.href = 'about:blank';
                }, 100);
              });
            }
          }
        }

        // If the banner is already in the DOM, attach listeners immediately
        attachCookieListeners();

        // Or observe DOM changes in case it gets inserted later (e.g. by HubSpot)
        const observer = new MutationObserver(attachCookieListeners);
        observer.observe(document.body, { childList: true, subtree: true });
    }
    }, []);
  
  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          padding: isMobile ? '10px' : '20px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        {/* Header and Image */}
        <Typography variant="h4" gutterBottom sx={{textAlign: 'center'}}>
          Ihr Ansprechpartner
        </Typography>
        <Box textAlign="center">
          <img
            src="/assets/Andreas_Metz_round.jpg"
            alt="Andreas Metz"
            style={{ width: '300px', borderRadius: '50%' }}
          />
          <Typography variant="h5" sx={{ marginTop: '10px' }}>
            Andreas Metz
          </Typography>
          <Typography variant="subtitle1">Geschäftsführer</Typography>
        </Box>

        <Typography variant="h5" sx={{ marginTop: '30px', marginBottom: '100px',textAlign: 'center', fontSize:'1.5rem', fontWeight:'bold'}}>
          Jetzt hier Eintragen
        </Typography>

        {/* HubSpot Form Container */}
        <Box
          id="hubspot-form"
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      </Box>

      <Footer />
    </Box>
  );
};

export default KontaktPage;
