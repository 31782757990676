// WerbepartnerPage.jsx

import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import { useFormik } from 'formik';
import * as yup from 'yup';

const WerbepartnerPage = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  // Form validation schema
  const validationSchema = yup.object({
    firstName: yup.string().required('Vorname ist erforderlich'),
    lastName: yup.string().required('Nachname ist erforderlich'),
    companyName: yup.string().required('Firmenname ist erforderlich'),
    street: yup.string().required('Straße ist erforderlich'),
    houseNumber: yup.string().required('Hausnummer ist erforderlich'),
    postalCode: yup.string().required('Postleitzahl ist erforderlich'),
    city: yup.string().required('Stadt ist erforderlich'),
    gender: yup.string().required('Geschlecht ist erforderlich'),
    // telephoneNumber and inquiry are optional
  });

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      gender: '',
      telephoneNumber: '',
      inquiry: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Handle form submission
      // Send the form data to backend API
      try {
        const response = await fetch('/api/werbepartner', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          // Success
          alert('Ihre Anfrage wurde erfolgreich gesendet.');
          resetForm();
        } else {
          // Handle error
          alert('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.');
        }
      } catch (error) {
        console.error(error);
        alert('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.');
      }
    },
  });

    useEffect(() => {
      // Load Instagram embed script
      const script = document.createElement('script');
      script.async = true;
      script.src = "//www.instagram.com/embed.js?locale=de_DE";
      document.body.appendChild(script);
    }, []);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          paddingX: '40px',
          paddingY: '40px',
          maxWidth: '1200px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        {/* Heading */}
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold', marginBottom: '20px' }}
        >
          Werden Sie jetzt Werbepartner!
        </Typography>

        {/* Under the heading: left text and right Instagram embedding */}
        <Grid container spacing={2} sx={{ padding: 0 }}>
          <Grid item xs={12} md={6} sx={{ padding: 0 }}>
            {/* Left text */}
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: '20px', textAlign: 'center' }}
            >
              Sichern Sie sich jetzt Ihren Platz! Tragen Sie Ihre Reservierungsdaten ein und nennen Sie unter „Anfrage“ das Event, das Datum und die Personenzahl.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: 0 }}>
      {/* Instagram embedding */}
      <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', width: '100%' }}>
        <blockquote
          className="instagram-media"
          data-instgrm-permalink="https://www.instagram.com/p/DAtYff_xlQJ"
          data-instgrm-version="14"
          style={{
            background: '#FFF',
            border: 0,
            margin: '1px auto',
            maxWidth: '540px',
            width: '100%',
          }}
        ></blockquote>
      </Box>
    </Grid>
        </Grid>

        {/* Second heading */}
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ marginTop: '40px', marginBottom: '40px', fontWeight: 'bold' }}
        >
          Profitieren Sie noch heute von den exklusiven Vorteilen einer Werbepartnerschaft!
        </Typography>

        {/* Under the heading: two columns separated by a line */}
        <Grid container spacing={2}>
          {/* Left column */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{ position: 'relative'}}
          >
            {/* Form */}
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Fordern Sie hier eine Rechnung an
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                {/* Gender */}
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    required
                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                  >
                    <InputLabel id="gender-label">Anrede</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender"
                      name="gender"
                      label="Anrede"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="male">Männlich</MenuItem>
                      <MenuItem value="female">Weiblich</MenuItem>
                      <MenuItem value="divers">Divers</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && (
                      <Typography color="error" variant="caption">
                        {formik.errors.gender}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {/* First Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="Vorname"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    required
                  />
                </Grid>
                {/* Last Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Nachname"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    required
                  />
                </Grid>
                {/* Company Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    label="Firmenname"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                    required
                  />
                </Grid>
                {/* Address: Street and House Number */}
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="street"
                    name="street"
                    label="Straße"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    error={formik.touched.street && Boolean(formik.errors.street)}
                    helperText={formik.touched.street && formik.errors.street}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="houseNumber"
                    name="houseNumber"
                    label="Nr."
                    value={formik.values.houseNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.houseNumber && Boolean(formik.errors.houseNumber)}
                    helperText={formik.touched.houseNumber && formik.errors.houseNumber}
                    required
                  />
                </Grid>
                {/* Address: Postal Code and City */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    label="PLZ"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    label="Stadt"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    required
                  />
                </Grid>
                {/* Telephone Number (optional) */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="telephoneNumber"
                    name="telephoneNumber"
                    label="Telefonnummer"
                    value={formik.values.telephoneNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.telephoneNumber && Boolean(formik.errors.telephoneNumber)}
                    helperText={formik.touched.telephoneNumber && formik.errors.telephoneNumber}
                  />
                </Grid>
                {/* Inquiry (optional) */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="inquiry"
                    name="inquiry"
                    label="Anfrage"
                    multiline
                    rows={4}
                    value={formik.values.inquiry}
                    onChange={formik.handleChange}
                    error={formik.touched.inquiry && Boolean(formik.errors.inquiry)}
                    helperText={formik.touched.inquiry && formik.errors.inquiry}
                  />
                </Grid>
                {/* Submit Button */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ fontSize: '16px', textTransform: 'none' }}
                  >
                    Anfrage senden
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        {/* Divider */}
        <Grid
          item
          xs={false}
          md={2}
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
          }}
        >
          <Divider orientation="vertical" flexItem sx={{ borderColor: 'grey.500', borderWidth: 1 }} />
        </Grid>
          {/* Right column */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center', marginTop: { xs: '20px', md: '0' } }}>
              <Button
                variant="contained"
                color="primary"
                href="https://www.paypal.com/paypalme/kunstkaleidoskop?country.x=DE&locale.x=de_DE"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ padding: '6px 16px', fontSize: '16px', textTransform: 'none' }}
              >
                Über PayPal buchen
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default WerbepartnerPage;