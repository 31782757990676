// PopupWidget.jsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, useTheme, Link, ButtonBase, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EventsPopupWidget = ({onClose}) => {
  const theme = useTheme();

  return (
    <Box
      // This Box is the dark, blurry overlay
      sx={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0,0,0,0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* This Box is the actual popup */}
      <Box
        sx={{
          width: '90%',
          maxWidth: '800px',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          textAlign: 'center',
          // Scale transition
          transform: 'scale(0.7)',
          animation: 'popupScale 0.2s ease-out forwards',
          '@keyframes popupScale': {
            '0%': {
              transform: 'scale(0.7)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
      >
        {/* Close button at the top right */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: theme.palette.text.primary
          }}
          aria-label="close popup"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Jetzt Gratis Gutschein sichern!!!
        </Typography>
        <Typography variant="body1" sx={{  }}>
          Zur Feier unserer Neueröffnung kannst du dir einen Gutschein im Wert von 20€ sichern. Alles, was du tun musst, ist dich bei unserer Kontaktseite zu registrieren und unseren Newsletter abonnieren. So geht's:
          <br></br><br></br>
        </Typography>
        <Typography variant="body1" sx={{textAlign:"left", mb: 3, paddingLeft:'2rem' }}>
          1. Erforderliche Kontaktdaten bei uns hinterlegen
          <br></br>
          2. Innerhalb weniger Tage den Gutschein per Email erhalten
          <br></br>
          3. Bei uns vor Ort einlösen!

        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
          {/* <Link onClick={handleClose} sx={{ textDecoration: "underline", cursor: "pointer" }}>
            Verstanden
          </Link> */}
          <ButtonBase href="/kontakt" variant="contained" onClick={onClose}
            sx={{
              borderRadius: '30px',
              backgroundColor: '#e88527',
              padding: { xs: '8px', md: '8px' },
              color: 'white',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              marginLeft: '1rem'
            }}
          >
            Hier eintragen
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

export default EventsPopupWidget;
