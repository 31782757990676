// ZahlungPage.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';

const ZahlungPage = () => {
  const theme = useTheme();

  return (
    <Box>
      <Navbar />
      {/* <PopupWidget /> */}
      <Box
        sx={{
          padding: '20px',
          maxWidth: '1200px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom marginBottom="2rem">
          Diese Seite befindet sich derzeit im Aufbau.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
};

export default ZahlungPage;
