// NeuerOeffnungPage.jsx

import { Box, useMediaQuery, Typography, Button, useTheme, Link, List, ListItem, Divider } from '@mui/material';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';

const NeuerOeffnungPage = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery('(min-width:1000px)');

    return (
        <Box>
            <Navbar />
            <Box
                sx={{
                    padding: '20px',
                    maxWidth: '800px',
                    margin: '0 auto',
                    backgroundColor: theme.palette.background.paper,
                    marginBottom: '25rem',
                }}
            >
                <Typography textAlign="center" variant="h4" gutterBottom>
                    Diese Seite befindet sich derzeit im Aufbau.
                </Typography>

                {/* Countdown */}
                {/* <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
          Countdown zum Start der Events
          </Typography>
          <Countdown date={targetDate} renderer={countdownRenderer} />
        </Box> */}
            </Box>
            <Box
                sx={{
                    padding: '20px',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    marginBottom: '20px',
                }}
            >
                <Box
                    sx={{
                        padding: '20px',
                        maxWidth: '1200px',
                        margin: '0 auto',
                        marginBottom: '20px',
                        fontSize: '1rem'
                    }}
                >
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default NeuerOeffnungPage;