// EventsPage.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Divider,
  ButtonBase,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Navbar from 'scenes/navbar';
import NavbarTest from 'scenes/navbartest'
import Footer from 'scenes/footer';
import PopupWidget from 'scenes/widgets/PopupWidget.jsx';
import DIVIDER_SVG from 'scenes/widgets/Divider_SVG.jsx';
import KKM_SVG from 'scenes/widgets/KKM_SVG';
import EventsPopupWidget from 'scenes/widgets/EventsPopupWidget';

const EventsPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const isEventsMobile = useMediaQuery('(min-width:1200px)');

  // Colors for each letter of "Events"
  const letterColors = ['#f2e198', '#60ade1', '#e88527', '#c796b7', '#cd1d17', '#fdd687'];
  const wordEvents = 'Events'.split('');

  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <Box>
      <Navbar />
      {/* <PopupWidget /> */}

      {/* SECTION 1 */}
      <Box
        sx={{
          top: '-100px',
          position: 'relative',
          backgroundImage: "url('/assets/events_img1.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          marginBottom: '-100px'
        }}
      >
        <Typography
          variant="h1"
          sx={{
            position: 'absolute',
            left: isEventsMobile ? undefined : "0px",
            bottom: '20px',
            padding: '20px',
            width: isEventsMobile ? '1200px' : undefined,
            margin: isEventsMobile ? '0 auto' : undefined,
            display: 'flex',
            fontSize: '4rem',
            fontStyle: 'italic'
          }}
        >
          {wordEvents.map((letter, index) => (
            <span key={index} style={{ color: letterColors[index] }}>
              {letter}
            </span>
          ))}
        </Typography>
      </Box>

      {/* Some text below the background image */}
      <Box
        sx={{
          padding: '20px',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      >
        <Typography variant="body1" sx={{ mb: 2 }}>
          Willkommen bei Kunstkaleidoskop&nbsp;<span style={{ fontSize: "1.5rem", fontFamily: "Playball, cursive" }}>Events</span>, der Eventreihe, die Kunst, Kreativität und Unternehmertum auf einzigartige Weise verbindet! Unsere Veranstaltungen bringen Schüler, Künstler und Unternehmer zusammen – für einen inspirierenden Austausch, spannende Begegnungen und wertvolle Vernetzung.
          <br></br>
          <br></br>
          Jedes unserer Events ist ein Unikat: individuell gestaltet, voller kreativer Impulse und mit wechselnden Themen, die Kunst erlebbar machen. Ob Ausstellung, Live-Kunst-Performance oder interaktiver Workshop – hier erwarten dich immer neue, überraschende Erlebnisse.
        </Typography>

        {/* Box with the button and image */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: {xs:'column', md:'row'},
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          {/* Button on the left */}
          <ButtonBase
            component={RouterLink}
            onClick={handleOpenPopup}
            sx={{
              borderRadius: '30px',
              backgroundColor: '#e88527',
              padding: { xs: '8px', md: '8px' },
              color: 'white',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              margin: { xs: '0 auto', md: 0 },
              marginBottom: { xs: '1rem', md:0},
              marginLeft: { xs: 'auto', md: '1rem' },
            }}
          >
            Zu den Events
          </ButtonBase>

          {showPopup && <EventsPopupWidget onClose={handleClosePopup} />}

          {/* Image on the right*/}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md:'row'},
              flexShrink: 0,
              margin: { xs: '0 auto', md: 0 },
              marginTop: { xs: '1rem', md: '4rem' },

            }}
          >
            <Box
              sx={{
                display: { xs: 'none', md:'flex'},
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginRight: { xs: 0, md:'2rem'}
              }}
            >
              {/* <ButtonBase
                component={RouterLink}
                to="/kontakt"
                sx={{
                  marginTop: '6rem',
                  borderRadius: '30px',
                  backgroundColor: "#e88527",
                  padding: { xs: '8px', md: '8px' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                variant="contained"
              >
                Hier Malkurs buchen
              </ButtonBase> */}
              <img
                src="/assets/speech_bubble.svg"
                alt="Right Side"
                style={{
                  marginBottom: '-32px',
                  width: '280px',
                }}
              />
            </Box>
            <img
              src="/assets/events_img2.jpg"
              alt="Right Side"
              style={{
                width: '280px',
              }}
            />
            <Box
              sx={{
                display: { xs: 'flex', md:'none'},
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginRight: { xs: 0, md:'1rem'},
                marginTop: '0.5rem'
              }}
            >
              <img
                src="/assets/speech_bubble.svg"
                alt="Right Side"
                style={{
                  width: '200px',
                  marginBottom: '3rem'
                }}
              />
              {/* <ButtonBase
                component={RouterLink}
                to="/kontakt"
                sx={{
                  borderRadius: '30px',
                  backgroundColor: "#e88527",
                  padding: { xs: '8px', md: '8px' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                variant="contained"
              >
                Hier Malkurs buchen
              </ButtonBase> */}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Divider / SVG to transition to Section 2 */}
      <Box sx={{ my: 4, width: "100vw", overflowX: "hidden" }}>
        <DIVIDER_SVG />
      </Box>

      {/* SECTION 2 */}
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
        }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          Unser Motto: Kunst, Kaffee und Mehr
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isNonMobileScreens ? 'row' : 'column',
            gap: '1rem',
          }}
        >
          {/* Left 30% = image + button */}
          <Box
            sx={{
              flexBasis: isNonMobileScreens ? '30%' : '100%',
              textAlign: 'center',
            }}
          >
            <KKM_SVG />
            <ButtonBase
              component={RouterLink}
              onClick={handleOpenPopup}
              sx={{
                borderRadius: '30px',
                backgroundColor: "#e88527",
                padding: { xs: '8px', md: '8px' },
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              variant="contained"
            >
              Zu den Events
            </ButtonBase>

            {showPopup && <EventsPopupWidget onClose={handleClosePopup} />}
          </Box>

          {/* Right 70% = text */}
          <Box sx={{ flexBasis: isNonMobileScreens ? '70%' : '100%' }}>
            <Typography variant="body1">
              Unsere Kunstkaleidoskop-Events stehen unter dem besonderen Motto “Kunst, Kaffee und mehr” – denn unsere Veranstaltungen sind mehr als nur Ausstellungen oder Netzwerktreffen. Sie sind ein Erlebnis für alle Sinne!
              <br></br>
              <br></br>
              Kunst – Jedes Event hat einen künstlerischen und kreativen Aspekt: von spannenden Kunstausstellungen über Live-Performances bis hin zu interaktiven Workshops.
              Kaffee – Genieße unsere Events in einer gemütlichen Atmosphäre mit ausgewählten Speisen und Getränken – perfekt für inspirierende Gespräche und kreative Begegnungen.
              Mehr – Hier beginnt deine individuelle Erfahrung! Unsere Events sind flexibel und können an die Wünsche unserer Gäste und Partner angepasst werden.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Divider / SVG to transition to Section 3 */}
      <Box sx={{ my: 4, width: "100vw", overflowX: "hidden" }}>
        <DIVIDER_SVG />
      </Box>

      {/* SECTION 3 */}
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
        }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          Mehr...
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Unsere Events sind so vielseitig wie die Menschen, die sie besuchen. Künstler können ihre Werke bei uns ausstellen, Kreative haben die Möglichkeit, an inspirierenden Mal- und Kunstkursen teilzunehmen, und Unternehmer können unsere Events nicht nur erleben, sondern auch selbst zum Gastgeber werden. Denn „Mehr“ bedeutet bei uns auch, dass wir Kunst und Genuss direkt zu dir bringen.
          <br></br><br></br>
          Wenn du beispielsweise ein Restaurant besitzt, könnte dein Event mit einem exklusiven Menü oder einer Weinverkostung kombiniert werden. In einer Zahnarztpraxis könnte ein Event rund um das Thema Kunst und Gesundheitsbewusstsein gestaltet werden, bei dem Patienten und Gäste in einer entspannten Atmosphäre Kunst erleben und sich gleichzeitig über moderne Zahnmedizin informieren. Ein Modegeschäft wiederum könnte „Mehr“ als eine stilvolle Kombination aus Kunst und Fashion definieren – mit einer Live-Kunst-Performance direkt zwischen den neuesten Kollektionen.
          <br></br><br></br>
          Dieses „Mehr“ macht unsere Kunstkaleidoskop-Events so einzigartig und flexibel – sie passen sich an ihre Umgebung und an die Menschen an, die sie erleben. Egal ob du dein Unternehmen auf besondere Weise präsentieren möchtest, ein kreatives Netzwerktreffen planst oder einfach Lust auf ein inspirierendes Event hast: Wir gestalten dein persönliches Kunst, Kaffee und mehr-Erlebnis!
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          mb: 2,
          overflow: 'hidden',
          height: { xs: 150, md: 'auto' },
        }}
      >
        <img
          src="/assets/events_img3.jpg"
          alt="Section 3"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>

      <Box
        id="schweitzer"
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
          scrollMarginTop: '160px', // Adjust based on your header height
        }}
      >
        <Typography variant="h3" sx={{ mb: 1 }}>
          Pamela Schweitzer
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Wir freuen uns, die talentierte Künstlerin Pamela Schweizer in unserer Galerie begrüßen zu dürfen. Ihre Werke, insbesondere ihre charakteristischen Clown-Zeichnungen, faszinieren durch Ausdruckskraft und Detailreichtum. Ab sofort stellt sie ihre Kunst bei uns aus und bereichert unser Programm mit Malkursen für Jung und Alt.
          <br></br><br></br>
          Für Schulkinder bietet Pamela kostenlose Workshops an, in denen sie die Kunst des Zeichnens von Clowns vermittelt und zeigt, wie man einen Rohling in ein einzigartiges Kunst-Kaleidoskop verwandelt. Die Teilnahme ist mit einem Gutschein gratis. Erwachsene können in 90-minütigen Kursen für 90 Euro ihre Kreativität entfalten und von Pamelas Expertise profitieren.
          <br></br><br></br>
          Nutzen Sie diese Gelegenheit, von einer außergewöhnlichen Künstlerin zu lernen und Ihre eigenen künstlerischen Fähigkeiten zu entdecken. Für weitere Informationen und zur Anmeldung besuchen Sie bitte unsere Kontaktseite und schreiben sie in das Nachrichtenfeld das Stichwort: <span style={{ fontStyle: "Italic" }}>Malkurs</span>
        </Typography>

        <Box
          sx={{
            display: 'flex'
          }}
        >
          <ButtonBase
            component={RouterLink}
            to="/kontakt"
            sx={{
              borderRadius: '30px',
              backgroundColor: '#e88527',
              padding: { xs: '8px', md: '8px' },
              color: 'white',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              margin: '0 auto',
            }}
          >
            Hier Malkurs buchen
          </ButtonBase>
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default EventsPage;
