// wettbewerb/index.jsx

import React, { useState } from 'react';
import {
  Box,
  useMediaQuery,
  Button,
  Typography,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  ButtonBase
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import Navbar from 'scenes/navbar';
import Footer from 'scenes/footer';
import PlaceholderPostsWidgetWettbewerb from 'scenes/widgets/PlaceholderPostsWidgetWettbewerb';
import MyPostWidget from 'scenes/widgets/MyPostWidget';
import PostsWidget from 'scenes/widgets/PostsWidget';
import Countdown from 'react-countdown';
import PopupWidget from 'scenes/widgets/PopupWidget.jsx';

// Import Lightbox components
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';

const WettbewerbPage = () => {
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const _id = user?._id;
  const picturePath = user?.picturePath;

  const [lightboxOpen, setLightboxOpen] = useState(false);

  // State variables for modals
  const [openLikeGuthabenModal, setOpenLikeGuthabenModal] = useState(false);
  const [openWochensiegerModal, setOpenWochensiegerModal] = useState(false);

    // Countdown renderer
    const countdownRenderer = ({ days, hours, minutes, seconds }) => {
      return (
        <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
          {days} Tage {hours} Stunden {minutes} Minuten {seconds} Sekunden
        </Typography>
      );
    };
  
    // Target date: January 1st, 2025, at 00:00 Uhr
    const targetDate = new Date('2025-01-01T00:00:00');

  return (
    <Box>
      <Navbar />
      {/* <PopupWidget /> */}
      <Box
        sx={{
          padding: '20px',
          maxWidth: '800px',
          margin: '0 auto',
          backgroundColor: theme.palette.background.paper,
          marginBottom: '20px',
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom>
          Diese Seite befindet sich derzeit im Aufbau.
        </Typography>

                {/* Countdown */}
                {/* <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
          Countdown zum Wettbewerbsstart
          </Typography>
          <Countdown date={targetDate} renderer={countdownRenderer} />
        </Box> */}
      </Box>

      <Box
        width="100%"
        padding="0rem 6%"
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap="0.5rem"
        justifyContent="center"
      >
        <Box
          flexBasis={isNonMobileScreens ? '75%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
        >
          <MyPostWidget picturePath={picturePath} />
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="20px"
            sx={{ padding: '0 6%', marginBottom: '20px' }}
          >
            <Box
              display={isNonMobileScreens ? 'flex' : undefined}
              justifyContent="space-between"
              sx={{width: isNonMobileScreens ? '100%' : undefined}}
            >
            {/* Wochensieger Box */}
            <Box
              flexBasis={isNonMobileScreens ? '45%' : '100%'}
              sx={{
                backgroundColor: theme.palette.custom.gelb,
                padding: "20px",
                paddingRight: '30px',
                paddingLeft: '30px',
                borderRadius: '8px',
                textAlign: 'center',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                position: 'relative',
                overflow: 'visible',
                marginBottom: isNonMobileScreens ? undefined : '1.5rem'
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  Unsere Wochensieger
                  <IconButton
                    size="small"
                    onClick={() => setOpenWochensiegerModal(true)}
                    sx={{
                      padding: 0,
                      position: 'absolute',
                      right: '-24px',
                      top: '-5px',
                      marginLeft: '4px',
                    }}
                  >
                    <HelpOutlineIcon
                      sx={{
                        fontSize: '20px',
                        color: theme.palette.text.secondary,
                      }}
                    />
                  </IconButton>
                </Typography>
              </Box>

              {/* Wochensieger Modal */}
              <Dialog
                open={openWochensiegerModal}
                onClose={() => setOpenWochensiegerModal(false)}
                disableScrollLock
              >
                <DialogTitle>
                  Wochensieger
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenWochensiegerModal(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  {/* Updated modal text */}
                  <Typography gutterBottom>
                    <strong>Wochensieger des Kunstkaleidoskop-Wettbewerbs</strong>
                  </Typography>
                  <Typography gutterBottom>
                    Der Wochensieger ist das Kunstkaleidoskop, das innerhalb des Wettbewerbs die meisten Likes insgesamt gesammelt hat. Jede Woche wird das Kunstwerk mit der höchsten Like-Anzahl zum Wochensieger gekürt, was dem Produzenten besondere Vorteile, Anerkennung und eine dauerhafte Wertsteigerung ermöglicht.
                  </Typography>
                  <Typography gutterBottom>
                    <strong>So wird der Wochensieger bestimmt:</strong>
                  </Typography>
                  <ul>
                    <li>Der Wochensieger wird durch die absolute Like-Anzahl ermittelt, sodass auch Kunstkaleidoskope, die schon länger im Wettbewerb sind, die Chance haben, Wochensieger zu werden.</li>
                  </ul>
                  <Typography gutterBottom>
                    <strong>Vorteile und Konsequenzen für den Wochensieger:</strong>
                  </Typography>
                  <ul>
                    <li>
                      <strong>Einmalige Auszeichnung:</strong> Der Wochensieger wird auf unserer Webseite und unserem Instagram-Kanal prominent hervorgehoben, was dem Kunstwerk und seinem Produzenten besondere Anerkennung und Reichweite verschafft.
                    </li>
                    <li>
                      <strong>Eingefrorener Like-Wert:</strong> Der aktuelle Like-Wert des Wochensiegers wird „eingefroren“ und als fester Betrag hinterlegt, zum Beispiel 20 €. Der Produzent kann diesen Betrag jederzeit in der Kunstkaleidoskop-Galerie einlösen und scheidet damit aus dem Wettbewerb aus. Das Kunstkaleidoskop geht dann in den Besitz von Kunstkaleidoskop über, bleibt jedoch dauerhaft auf unserer Webseite und Instagram-Seite öffentlich sichtbar und kann weiterhin Likes sammeln.
                    </li>
                    <li>
                      <strong>Verbleib und Weitergabe des Kunstwerks:</strong> Sollte der Produzent das Kunstkaleidoskop verkaufen, gehen alle Rechte – einschließlich der gesammelten Likes und der Möglichkeit zur Nutzung der exklusiven Partner-Angebote – auf den Käufer über. So bleibt das Kunstwerk wertvoll, da es weiterhin Likes und damit exklusive Vorteile sammeln kann, die der neue Besitzer nutzen kann.
                    </li>
                  </ul>
                  <Typography gutterBottom>
                    <strong>Exklusive Vorteile bei Werbepartnern</strong><br />
                    Der Wochensieger eröffnet dem jeweiligen Besitzer besondere Rabatte und Angebote bei unseren Werbepartnern, die sich nach der Anzahl der Likes auf Instagram richten. Ein gestaffeltes Beispiel:
                  </Typography>
                  <ul>
                    <li>1.000 Likes: Der Besitzer erhält z. B. 10 % Rabatt in einem teilnehmenden Restaurant.</li>
                    <li>10.000 Likes: Der Rabatt könnte auf 20 % steigen.</li>
                    <li>100.000 Likes: Der Besitzer könnte eine kostenlose Mahlzeit für eine Person im Restaurant erhalten.</li>
                    <li>1 Million Likes: Der Besitzer hat die Möglichkeit, eine Person zu einer kompletten Verköstigung mit Speisen und Getränken einzuladen.</li>
                  </ul>
                  <Typography gutterBottom>
                    Da das Kunstkaleidoskop nach dem Verkauf weiter im Wettbewerb bleibt und Likes sammeln kann, erhöht sich sein Wert für jeden Besitzer. Diese Likes bieten kontinuierlich wertvolle Vorteile und Rabatte bei unseren Werbepartnern – ein echter Mehrwert für jeden, der dieses besondere Kunstwerk in Händen hält.
                  </Typography>
                </DialogContent>
              </Dialog>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Box
                  component="video"
                  src="/assets/Magnus.mp4"
                  alt="Wochensieger"
                  onClick={() => setLightboxOpen(true)}
                  autoPlay
                  muted
                  controls
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    height: 'auto',
                    borderRadius: '8px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ButtonBase
                  variant="contained"
                  href="https://www.instagram.com/kunstkaleidoskop/"
                  target="_blank"
                  sx={{
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.025)',
                    },
                    backgroundColor: '#1a5e7d',
                    borderRadius: '30px',
                    padding: '8px',
                    color: 'white'
                  }}
                >
                  Zum Instagram Post
                </ButtonBase>
              </Box>
            </Box>
            {/* Like-Guthaben-Pool Box */}
            <Box
              flexBasis={isNonMobileScreens ? '45%' : '100%'}
              sx={{
                backgroundColor: theme.palette.custom.babyblau,
                padding: '20px',
                paddingRight: '30px',
                paddingLeft: '30px',
                borderRadius: '8px',
                textAlign: 'center',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                position: 'relative',
                overflow: 'hidden',
                height: 'fit-content',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  display: 'inline-block',
                  position: 'relative',
                }}
              >
                Like-Guthaben-Pool
                <IconButton
                  size="small"
                  onClick={() => setOpenLikeGuthabenModal(true)}
                  sx={{
                    padding: 0,
                    position: 'absolute',
                    right: '-24px',
                    top: '-5px',
                    marginLeft: '4px',
                  }}
                >
                  <HelpOutlineIcon
                    sx={{
                      fontSize: '20px',
                      color: theme.palette.text.secondary,
                    }}
                  />
                </IconButton>
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                1 Like = 1€
              </Typography>
            </Box>

            {/* Like-Guthaben-Pool Modal */}
            <Dialog
              open={openLikeGuthabenModal}
              onClose={() => setOpenLikeGuthabenModal(false)}
              disableScrollLock
            >
              <DialogTitle>
                Like-Guthaben-Pool
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenLikeGuthabenModal(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                {/* Updated modal text */}
                <Typography gutterBottom>
                  <strong>Der Like-Guthaben-Pool</strong>
                </Typography>
                <Typography gutterBottom>
                  Der Like-Guthaben-Pool ist ein wachsender Geldtopf, der die kreativen Werke der teilnehmenden Schüler mit einem echten Gegenwert belohnt. Mit jedem verkauften Kunstkaleidoskop-Rohling und zusätzlichen Beiträgen unserer Werbepartner steigt dieser Pool und damit der Wert jedes “Likes”, das ein Kunstkaleidoskop erhält.
                </Typography>
                <Typography gutterBottom>
                  <strong>So funktioniert der Like-Guthaben-Pool:</strong>
                </Typography>
                <ul>
                  <li>1 € pro verkauftem Rohling fließt in den Pool.</li>
                  <li>Zusätzliche Beiträge unserer Werbepartner lassen den Pool weiter anwachsen.</li>
                </ul>
                <Typography gutterBottom>
                  Der aktuelle Wert des Like-Guthaben-Pools bestimmt, wie viel jedes “Like” auf ein Kunstwerk wert ist. Je mehr Likes ein Kunstkaleidoskop sammelt, desto höher ist der Betrag, den der Schüler für sein Werk erzielen kann, wenn er sich entscheidet, es einzulösen oder zu verkaufen.
                </Typography>
                <Typography gutterBottom>
                  <strong>Einlösen jederzeit möglich</strong><br />
                  Für den Start garantieren wir, dass jedes Kunstkaleidoskop, das auf unserer Webseite veröffentlicht ist, sofort und zu jeder Zeit in der Kunstkaleidoskop-Galerie abgegeben und gegen seinen Like-Wert oder den Mindestbetrag eingelöst werden kann. Für die ersten 100 Kunstkaleidoskope sichern wir mindestens den Kaufpreis von 9,90 € als Rückzahlung zu, sobald sie abgegeben werden.
                </Typography>
              </DialogContent>
              </Dialog>
            </Box>
          </Box>
          {/* <PostsWidget /> */}
        </Box>
      </Box>
      <Box
        width="100%"
        padding="0rem 6%"
        display={isNonMobileScreens ? 'flex' : 'block'}
        gap="0.5rem"
        justifyContent="center">
                  <Box
          flexBasis={isNonMobileScreens ? '75%' : undefined}
          mt={isNonMobileScreens ? undefined : '2rem'}
        >
              <PlaceholderPostsWidgetWettbewerb></PlaceholderPostsWidgetWettbewerb>
              </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default WettbewerbPage;
