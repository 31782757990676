// components/Divider.jsx
import React from 'react';
import { Box } from '@mui/material';

const Divider = () => {
    return (
        <Box
            component="div"
            sx={{
                width: '110vw',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                my: 2,
                position: 'relative',
                left: '50%',
                transform: 'translateX(-50%)',
                marginTop: '4rem',
                marginBottom: '4rem',
            }}
        >
            <img
                src="/assets/separation_lines.svg"
                alt="Divider"
                style={{ width: '100%', height: 'auto' }}
            />
        </Box>
    );
};

export default Divider;