// PopupWidget.jsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, useTheme, Link, ButtonBase, IconButton, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Utility functions for cookies (or use a library like js-cookie)
function setCookie(name, value, days = 365) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1);
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

const PopupWidget = () => {
  const theme = useTheme();

  // Control for showing the popup
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if the user has previously clicked "Verstanden"
    // const popupClosed = getCookie('popupClosed');
    // if (!popupClosed) {

    // Check if the user has previously submitted a Kontakt form
    const formSubmitted = getCookie('formSubmitted');
    if (!formSubmitted) {
      // Show popup after 1.2 seconds
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    // Set a cookie so it doesn't show again
    // setCookie('popupClosed', 'true', 365);
  };

  if (!showPopup) {
    return null; // Nothing to render if popup is closed or the cookie is set
  }

  return (
    <Box
      // This Box is the dark, blurry overlay
      sx={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0,0,0,0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* This Box is the actual popup */}
      <Box
        sx={{
          width: { xs: '95%', sm: '90%' },
          paddingRight: '2.5rem !important',
          paddingLeft:'2.5rem !important',
          maxWidth: '800px',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          textAlign: 'center',
          // Scale transition
          transform: 'scale(0.7)',
          animation: 'popupScale 0.2s ease-out forwards',
          '@keyframes popupScale': {
            '0%': {
              transform: 'scale(0.7)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
      >
        {/* Close button at the top right */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: theme.palette.text.primary
          }}
          aria-label="close popup"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Jetzt gratis 20-Euro-Gutschein sichern!
        </Typography>
        <Typography variant="body1" sx={{  }}>
          Zur Feier unserer Neueröffnung schenken wir dir einen Gutschein im Wert von 20 Euro! Alles, was du tun musst:
          <br></br><br></br>
        </Typography>
        <Typography component="ol" variant="body1" sx={{ textAlign: "left", mb: 3, pl: "2rem" }}>
          <li>Trage deine erforderlichen Kontaktdaten auf unserer Kontaktseite ein.</li>
          <li>Erhalte sofort deinen Gutschein per E-Mail.</li>
          <li>Löse ihn bis zum 30. April bei uns vor Ort ein.</li>
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
          {/* <Link onClick={handleClose} sx={{ textDecoration: "underline", cursor: "pointer" }}>
            Verstanden
          </Link> */}
          <ButtonBase href="/kontakt" variant="contained" onClick={handleClose}
            sx={{
              borderRadius: '30px',
              backgroundColor: '#e88527',
              padding: { xs: '8px', md: '8px' },
              color: 'white',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            Jetzt eintragen und profitieren!
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

export default PopupWidget;
